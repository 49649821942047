import './App.less';
import { Cadastro } from './pages/Cadastro';
import { Rotas } from './Rotas';

function App() {
  return (
    <div className="App">
      <Rotas/>
    </div>
  );
}

export default App;
